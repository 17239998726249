<template>
    <div class="content-title-box">
        <div class="main-content-box">
            <div class="content-title">
                {{ link_field }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentTitle",
    props: ['link_field'],
}
</script>

<style scoped>
    .content-title-box {
        width: 100%;
        background-color: #fafafa;
    }

    .content-title{
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
        height: 45px;
        line-height: 45px;
        color: #333333;
        font-size: 18px;
        font-weight: bold;
    }


</style>
