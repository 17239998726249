<template>
    <content-title link_field="关于我"></content-title>
    <div class="main-content-box">
        <div style="max-width: 1250px;margin: 0 auto;">
            <div style="padding: 40px 0 100px 0;">
                <div class="about-box">
                    <div class="about-text-box">
                        <div class="about-text-title1"><span>OUR VISION</span></div>
                        <div class="about-text-title"><span>关于我</span></div>
                        <div class="about-text-content">
                            <span>赵孟</span>
                            <span>来自山西，现居北京。</span>
                            <span>涉及方向：网络爬虫、Web 开发等。</span>
                            <span>爱生活、爱艺术、爱书画、爱音乐、爱设计、爱编程。</span>
                        </div>
                    </div>


                    <div class="about-text-box">
                        <div class="about-text-title1"><span>ABOUT ME</span></div>
                        <div class="about-text-title"><span>关于我</span></div>
                        <div class="about-text-content">
                            <span>追求优雅的代码,喜欢好看的UI</span>
                            <span>热爱运用各种技术探索某种业务场景的最优解。</span>
                            <span>大道至简，尝试复杂问题简单化。</span>
                            <span>爱抽象思维，尝试使用设计模式来实际运用解决问题。</span>
                        </div>
                    </div>




                </div>


<!--                <div>-->
<!--                    {\__/}<br />-->
<!--                    ( • - •)<br />-->
<!--                    /つ 👙穿上它<br />-->
<!--                    {\__/}<br />-->
<!--                    ( • - •)<br />-->
<!--                    /つ 💄涂上它<br />-->
<!--                    {\__/}<br />-->
<!--                    ( • - •)<br />-->
<!--                    /つ 🎀戴上它<br />-->
<!--                    {\__/}<br />-->
<!--                    ( • - •)<br />-->
<!--                    /つ 👠穿上它<br />-->
<!--                    {\__/}<br />-->
<!--                    ( • - •)<br />-->
<!--                    /つ 👗再穿这个<br />-->
<!--                </div>-->

                <div class="about-img-box">
                    <div v-for="item in aboutImg" style="flex: 0 1 32.3%;width: 100%;padding: 20px 0;">
                        <div style="width: 100%;">
                            <el-image
                                style="width: 100%;"
                                :src="item.src"
                                :preview-src-list="srcList"
                                :hide-on-click-modal="true"
                            >
                            </el-image>
<!--                            <img :src="item.src" alt="" style="width: 100%;">-->
                        </div>
                        <div style="padding-top: 5px;display: flex;justify-content: center;align-items: center;flex-direction: column;">
                            <span style="font-size: 13px;color: #e74c3c;">{{ item.title_e }}</span>
                            <span style="padding-top: 5px;color: #333333;font-size: 18px;">{{ item.title }}</span>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

</template>

<script>
import ContentTitle from "../components/public/ContentTitle";
import {ElMessage} from "element-plus";

export default {
    name: "AboutMe",
    components: {
        ContentTitle,
    },
    data(){
        return {
            aboutData: [
                {title: '运用批判性思维和想象力', title_e: 'OUR VISION', content: '\n' +
                        '追求优雅的代码,喜欢好看的UI。\n' +
                        '热爱运用各种技术探索某种业务场景的最优解。\n' +
                        '大道至简，尝试复杂问题简单化。\n' +
                        '爱抽象思维，尝试使用设计模式来实际运用解决问题。'},
                {title: '最高的创造性标准', title_e: 'OUR MISSION', content: '起飞页自助建站平台全面采用第二代云服务器和云网络。其中，服务器硬件使用国内领先的Xeon处理器、32个物理CPU核心、RAID10存储、ECC内存、SSD硬盘，高配服务器在提供超高性能的同时，更可减少出错几率；另外，优质、昂贵的中港加速网络确保您在大陆地区也可以迅速稳定地打开香港的网站。'},
            ],
          aboutImg:'',
          srcList:''
        }
    },
  created() {
      this.getImgList()
  },
  methods: {
      initImgList(data){
        this.srcList=data
        this.aboutImg = [
          {title: '学习中的我', title_e: 'Learning', src: data[0]},
          {title: '努力工作也是我', title_e: 'Woring', src: data[1]},
          {title: '放飞的自我', title_e: 'Enterment', src: data[2]},
        ]
      },


      getImgList(){
        let that = this;
        that.axios.get('/img_list')
        .then(function (res) {
          let data = res.data.data
          that.initImgList(data)
        })
        .catch(function (error) {
          ElMessage({
            showClose: true,
            message: '图片列表获取失败，请刷新',
            type: 'error'
          })
        })
      }
    }
}
</script>

<style>

    .about-box{
        display: flex;
        justify-content: flex-start;
    }

    .about-text-box{
        flex: 1;
        width: 100%;
        padding-top: 20px;
    }

    .about-text-title1{
        font-size: 10px;
        color: #e74c3c;
        padding: 0 0 5px 0;
    }
    .about-text-title{
        font-size: 24px;
        color: #333333;
    }

    .about-text-content{
        padding: 5px 0;
        padding-right: 68px;
        line-height: 26px;
        font-size: 14px;
        color: #666666;
    }
    .about-text-content span{
        display: block;
        padding: 7px 0;
        font-size: 15px;
    }


    .about-img-box{
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
    }

    @media screen and (min-width: 0px) and (max-width: 990px) {
        .about-box,.about-img-box{
            flex-direction: column;
        }
        .about-text-content{
            padding-right: 0;
        }

    }

    .el-image-viewer__img{
        max-width: 95% !important;
    }
</style>
